<media-item-header></media-item-header>

<div class="container main-container">
    <article class="news-article" *ngIf="article$ | async as article">
        <ad-host slot="ads.shared" class="shared-ad-host"></ad-host>
        <h1 class="title">
            <a [routerLink]="['/news', article.id]">{{article.title}}</a>
        </h1>
        <style>
          .featuredImage{width:100%; max-width:680px; margin: 20px 0;}
          .featuredImage img{width:100%;}
        </style>
        <div class="featuredImage">
          <img [src]="article.meta.image_big != null ? article.meta.image_big : article.meta.image">
        </div>
        <div class="meta">
            <div class="date"><strong>{{article.created_at | formattedDate}}</strong></div>
            <ng-container *ngIf="article?.meta?.source">
                <div class="separator"></div>
              <ng-container
                *ngIf="article.meta.news_by; then bySource; else byBT">
              </ng-container>
              <ng-template #bySource>
                <div class="source">
                  <strong>{{ article.meta.news_by }}</strong>
                </div>
              </ng-template>
              <ng-template #byBT>
                <div>
                  <strong>by BengaliTollywood</strong>
                </div>
              </ng-template>
                <div class="separator"></div>
                <div class="source"><strong>{{article.meta.source}}</strong></div>
            </ng-container>
        </div>
        <div class="content">
            <div class="body" [innerHTML]="trustHtml(article.body)"> </div>
            <ng-container
              *ngIf="article.meta.news_link; then linkIT;">
            </ng-container>
            <ng-template #linkIT>
              <a href="{{article.meta.news_link}}" target="_blank">See full article at {{article.meta.source}}</a>
            </ng-template>

        </div>
    </article>

    <aside class="sidebar">
        <div class="header" trans>Other news</div>
        <div class="articles">
            <div class="article" *ngFor="let article of sidebarArticles$ | async">
                <img [src]="article?.meta?.image">
                <div class="meta">
                    <a [routerLink]="['/news', article.id, article.slug]" class="title">{{article.title}}</a>
                    <div class="subtitle">
                        <ng-container *ngIf="article?.meta?.source">
                            <span class="source"> {{article.meta.source}}</span>
                        </ng-container>
                        <span>{{article.created_at | formattedDate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </aside>

    <loading-indicator [isVisible]="loading$ | async" class="overlay overlay-light"></loading-indicator>
</div>

<footer></footer>
